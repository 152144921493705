const data = [
  { name: "Home", route: "/home" },
 // { name: "Equipe", route: "/equipe" },
  //{ name: "Sobre o Processo", route: "/sobre-processo" },
  { name: "Lista 123 Milhas", route: "/lista-123milhas" },
  { name: "Dúvidas", route: "/duvidas" },
  //{ name: "Dúvidas", route: "/faq" },
  //{ name: "Relatórios Mensais", route: "/relatorios-mensais" },
  //{ name: "Perguntas Frequentes", route: "/faq" },
  { name: "Contato", route: "/contato" },
];

export default [...data];

<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col>
                    <div class="font-weight-bold mt-6 title-page">Cadastro</div>
                    <p>
                        Preencha os campos abaixo para realizar o cadastro.
                    </p>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="validForm">
                <v-row>
                    <v-col>
                        <v-text-field dense id="name" label="Nome completo" v-model="dataset.data.name" required
                            :rules="[$rules.required]" outlined hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field dense id="registration" label="CPF/CNPJ" v-model="dataset.data.registration"
                            required
                            v-mask="dataset.data.registration && dataset.data.registration.length < 15 ? this.$masks.cpf : this.$masks.cnpj"
                            :rules="[$rules.required, (dataset.data.registration && dataset.data.registration.length < 15 ? $rules.cpf : $rules.cnpj)]"
                            outlined hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-4" v-if="isCNPJ">
                    <v-col class="d-flex align-center">
                        <v-checkbox v-if="isCNPJ" v-model="dataset.data.is_me_epp" label="ME/EPP" hide-details="auto">
                        </v-checkbox>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mt-5 ml-3" small :color="'primary'" v-bind="attrs"
                                    v-on="on">mdi-help-circle</v-icon>
                            </template>
                            <span>Caso a empresa se enquadre como microempresa ou empresa de pequeno porte, marque esta
                                opção.</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense label="Celular" v-model="dataset.data.phone_number" required
                            :rules="[$rules.required]" outlined hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense class="mb-2" id="email" label="E-mail" v-model="dataset.data.email" required
                            :rules="[$rules.required, $rules.email]" outlined hide-details="auto">
                        </v-text-field>
                        <span class="important-text">
                            * De preferência, crie este cadastro com o mesmo e-mail utilizado no site da 123
                            Milhas/Hotmilhas,
                            em
                            que recebeu o comprovante de pagamento da sua compra e/ou venda
                        </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense outlined label="Senha"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" required
                            :rules="[$rules.required, $rules.strongPassword]" :type="showPassword ? 'text' : 'password'"
                            v-model="dataset.data.password" @click:append="showPassword = !showPassword"
                            hide-details="auto" id="password"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined
                            counter required :rules="[$rules.required, $rules.fileSize(4)]" show-size
                            hint="O arquivo não pode ser superior a 4MB" hide-details="auto"
                            label="Documento de identificação (CPF, RG ou CNH / Contrato Social e Procuração)"
                            :clearable="true" @change="onFileSelected($event)" ref="logoFile">
                        </v-file-input>
                    </v-col>
                </v-row>

                <v-row align="center">
                    <v-col cols="auto">
                        <span><strong>Possui procurador?</strong></span>
                    </v-col>
                    <v-col cols="auto">
                        <v-radio-group v-model="procuratorQuestion" row>
                            <v-radio label="Sim" :value="true"></v-radio>
                            <v-radio label="Não" :value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>

                <template v-if="procuratorQuestion">

                    <v-row>
                        <v-col>
                            <v-text-field dense id="procurator_name" label="Nome procurador"
                                v-model="dataset.data.procurator_name" required :rules="[$rules.required]" outlined
                                hide-details="auto">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field dense id="procurator_federal_registration" label="Número OAB"
                                v-model="dataset.data.procurator_federal_registration" required
                                :rules="[$rules.required]" outlined hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-autocomplete :disabled="dataset.loading" dense required :items="dataset.statesList"
                                label="OAB UF" v-model="dataset.data.procurator_federal_registration_uf"
                                item-value="name" item-text="name" :rules="[$rules.required]" outlined
                                hide-details="auto">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field dense id="procurator_email" label="E-mail"
                                v-model="dataset.data.procurator_email" required :rules="[$rules.required]" outlined
                                hide-details="auto">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined
                                counter required :rules="[$rules.required, $rules.fileSize(4)]" show-size
                                hint="O arquivo não pode ser superior a 4MB" hide-details="auto" label="Documento OAB"
                                :clearable="true" @change="onProcuratorSelected($event)" ref="procurator_file">
                            </v-file-input>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined
                                counter required :rules="[$rules.required, $rules.fileSize(4)]" show-size
                                hint="O arquivo não pode ser superior a 4MB" hide-details="auto"
                                label="Documento procuração" :clearable="true" @change="onProcurationSelected($event)"
                                ref="procuration_file">
                            </v-file-input>

                        </v-col>
                    </v-row>
                </template>

                <v-row no-gutters class="mb-4">
                    <v-col>
                        <v-checkbox :rules="[$rules.required]" v-model="dataset.data.terms">
                            <template v-slot:label>
                                <span>
                                    Eu li e concordo com os
                                    <a class="checkbox-link" href="/termos-de-uso.pdf" target="_blank"
                                        @click.prevent="openPdf('/termos-de-uso.pdf')">Termos de Uso</a>
                                    e a
                                    <a class="checkbox-link" href="/politicas-de-privacidade.pdf" target="_blank"
                                        @click.prevent="openPdf('/politicas-de-privacidade.pdf')">Política de
                                        Privacidade</a>
                                </span>

                            </template>
                        </v-checkbox>
                        <span class="important-text">
                            * Ao aceitar você estará ciente das práticas de coleta, uso, e divulgação de informações
                            pessoais descritas
                            nas
                            políticas mencionadas, e autorizo o processamento dos meus dados de acordo com tais termos.
                        </span>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-text>
            <v-btn color="primary" @click.native="create" :loading="loading">Finalizar</v-btn>
            <v-btn color="grey" text @click.native="goBack">Cancelar</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import 'moment/locale/pt-br';
import statesList from "@/common/data/states-list.data";

import { eventHub } from "@/main";

import axios from "axios"

import string from "@/common/util/string"

export default {
    data: () => ({
        showPassword: false,
        validForm: false,
        loading: false,
        procuratorQuestion: false,
        dataset: {
            data: {
                is_me_epp: false,
            },
            statesList: statesList,
        },

    }),

    mounted: function () {
    },

    created() {
    },

    computed: {
        // getMask() {
        //     return
        // }

        isCNPJ() {
            return this.dataset.data.registration && this.dataset.data.registration.length >= 18;
        },

    },

    methods: {
        onFileSelected(file) {
            this.dataset.data.file = file;
        },

        onProcurationSelected(procuration_file) {
            this.dataset.data.procuration_file = procuration_file;
        },

        onProcuratorSelected(procurator_file) {
            this.dataset.data.procurator_file = procurator_file;
        },

        openPdf(pdfPath) {
            window.open(`${window.location.origin}${pdfPath}`, '_blank');
        },


        async create() {

            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja finalizar o cadastro?',
                title: 'Cadastro',
                actions: {
                    false: 'Cancelar',
                    true: 'Finalizar'
                }
            })

            if (!proceedAction) return;

            this.loading = true;

            const payload = this.buildPayload();

            try {
                await axios.post('/api/users', payload);

                await this.$dialog.confirm({
                    text: 'Cadastro realizado com sucesso! Um e-mail foi enviado para a sua caixa de entrada, acesse-o para verificar sua conta.',
                    title: 'Sucesso',
                    actions: {
                        true: 'OK'
                    }
                })

                this.goBack()
            } catch (error) {
                if (error && error.response && error.response.data.content) {
                    const errorTmp = error.response.data.content.error;
                    if (errorTmp.code == 1032) {
                        this.$dialog.confirm({
                            text: 'Seu CPF/CPNJ foi vinculado a mais de um nome na lista de credores. Seu pedido de habilitação/divergência foi registrado, mas deverá ser complementado. A Administração Judicial entrará em contato por e-mail com instruções sobre como prosseguir.',
                            title: 'Atenção',
                            actions: {
                                true: 'OK'
                            }
                        })
                    }
                }
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.loading = false;
            }

        },

        goBack() {
            this.dataset.data = {};
            eventHub.$emit('DIALOG-REGISTER', false)
        },

        buildPayload() {
            const formData = new FormData()

            formData.append('name', this.dataset.data.name);
            formData.append('registration', string.sanitizeCpfCnpj(this.dataset.data.registration));
            formData.append('phone_number', string.sanitizePhone(this.dataset.data.phone_number));
            formData.append('email', this.dataset.data.email);
            formData.append('password', this.dataset.data.password);
            formData.append('is_me_epp', this.dataset.data.is_me_epp);

            formData.append('terms', this.dataset.data.terms);

            formData.append('file', this.dataset.data.file)

            if (this.procuratorQuestion) {
                formData.append('procurator_name', this.dataset.data.procurator_name);
                formData.append('procurator_email', this.dataset.data.procurator_email);
                formData.append('procurator_federal_registration', this.dataset.data.procurator_federal_registration);
                formData.append('procurator_federal_registration_uf', this.dataset.data.procurator_federal_registration_uf);
                formData.append('procurator_file', this.dataset.data.procurator_file);
                formData.append('procuration_file', this.dataset.data.procuration_file);
            } else {
                formData.append('procurator_name', '');
                formData.append('procurator_email', '');
                formData.append('procurator_federal_registration', '');
                formData.append('procurator_federal_registration_uf', '');
                formData.append('procurator_file', '');
                formData.append('procuration_file', '');
            }
            return formData;
        }
    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

p {
    text-align: justify;
}
</style>

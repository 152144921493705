<template>
  <v-card color="background" height="100%">
    <v-row align="center" justify="center" style="margin-top: -50px; margin-bottom: 10px;">
      <v-col align="center">
        <img src="@/assets/logo-paoli.png" width="180px" />
      </v-col>
      <v-col align="center">
        <img src="@/assets/logo-bej.svg" width="200px" />
      </v-col>
      <v-col align="center">
        <img src="@/assets/logo-inocencio-paula.png" width="300px" />
      </v-col>
    </v-row>

    <v-app-bar app dark color="primary" clipped-left style="position: relative;" class="hidden-md-and-down">
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer" ></v-app-bar-nav-icon> -->

      <v-spacer class="d-none d-md-flex"></v-spacer>

      <div class="d-flex flex-wrap">


        <v-btn v-for="(item, index) in sidebarMenu" :key="index" text :to="item.route">
          {{ item.name }}
        </v-btn>

        <!-- About Process Dropdown Menu -->
        <v-menu min-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="ml-2">
              Sobre o Processo
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="dropdown-menu-list">
            <v-list-item link :to="child.route" v-for="(child, index) in dropDownAboutProcess" :key="index"
              class="submenu-item">
              <v-list-item-content>
                <v-list-item-title class="submenu-text text-white-on-hover">{{ child.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Creditor Field Dropdown Menu -->
        <v-menu min-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="ml-2">
              Área do Credor
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="dropdown-menu-list">
            <v-list-item link :to="child.route" v-for="(child, index) in dropDownCreditorArea" :key="index"
              class="submenu-item">
              <v-list-item-content>
                <v-list-item-title class="submenu-text text-white-on-hover">{{ child.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-if="user" min-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon class="mr-2">mdi-account</v-icon>
              {{ 'Olá, ' + normalizeName(user.name) }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click.native="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-menu>

        <v-btn v-else text @click="dialogAccess = true">
          <v-icon class="mr-2">mdi-account</v-icon>
          Cadastre-se / Login
        </v-btn>

      </div>

      <v-spacer class="d-none d-md-flex"></v-spacer>
    </v-app-bar>


    <!-- MENU MOBILE -->

    <div class="px-4 hidden-lg-and-up">
      <v-col class="" cols="12" justify="space-between" align="start">
        <v-btn icon color="black" @click="toogleMenuApp">
          <v-icon size="40">mdi-menu</v-icon>
          <!-- <span class="font-weight-bold ml-4">MENU</span> -->
        </v-btn>
      </v-col>
    </div>

    <v-row class="hidden-lg-and-up" v-if="menuApp">
      <v-col cols="12" justify="space-between" align="start">
        <v-col cols="12">
          <div class="py-2" v-for="(item, index) in sidebarMenu" :key="index">
            <template v-if="!item.children || item.children.length === 0">
              <v-btn @click="toogleMenuApp" text :to="item.route" class="menu-item">
                {{ item.name }}
              </v-btn>
            </template>
          </div>
        </v-col>

        <v-col cols="12" class="py-2">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="#2c4093" v-bind="attrs" v-on="on" class="menu-buttons" style="margin-left: 10px;">
                <span>
                  SOBRE O PROCESSO
                  <span>
                    <v-icon right>mdi-chevron-down</v-icon>
                  </span>
                </span>
              </v-btn>
            </template>

            <v-list class="dropdown-menu-list hidden-lg-and-up">
              <v-list-item v-for="(item, index) in dropDownAboutProcess" :key="index" :to="item.route" link
                class="submenu-item">
                <v-btn text :to="item.route" class="text-white-on-hover">
                  {{ item.name }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <v-col cols="12" class=" py-2">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="#2c4093" v-bind="attrs" v-on="on" class="ml-2 menu-buttons">
                <span>
                  AREA DO CREDOR
                  <span>
                    <v-icon right>mdi-chevron-down</v-icon>
                  </span>
                </span>
              </v-btn>
            </template>
            <v-list class="dropdown-menu-list hidden-lg-and-up">
              <v-list-item v-for="(item, index) in dropDownCreditorArea" :key="index" :to="item.route" link
                class="submenu-item">
                <v-btn text :to="item.route" class="text-white-on-hover">
                  {{ item.name }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <v-menu v-if="user" min-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon class="mr-2">mdi-account</v-icon>
              {{ 'Olá, ' + normalizeName(user.name) }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click.native="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-menu>

        <v-btn v-else text @click="dialogAccess = true">
          <v-icon class="mr-2">mdi-account</v-icon>
          Cadastre-se / Login
        </v-btn>
      </v-col>

    </v-row>

    <v-dialog persistent v-model="dialogRegister" :width="600">
      <register-component></register-component>
    </v-dialog>

    <v-dialog v-model="dialogForgotPassword" :width="600">
      <forgot-password-component></forgot-password-component>
    </v-dialog>

    <v-dialog v-model="dialogAccess" :width="600">
      <access-component></access-component>
    </v-dialog>

    <progress-bar></progress-bar>

    <router-view></router-view>

    <v-footer padless class="footer-copyright py-5 mt-10 px-12">
      <v-row justify="center" align="center">
        <v-spacer></v-spacer>
        <v-col align="center">
          <span color="white">
            © {{ new Date().getFullYear() }} Recuperação Judicial 123 Milhas | Todos direitos reservados
          </span>
        </v-col>
        <v-col align="right">
          <a target="_blank" href="https://mazzitech.com.br">
            <div>
              <span>Powered by</span>
            </div>
            <img src="@/assets/logo-mazzitech.svg" loading="lazy" alt="Mazzitech" class="img-fluid image-footer"
              style="width: 150px;">
          </a>
        </v-col>
      </v-row>
    </v-footer>
  </v-card>
</template>

<script>
import { eventHub } from "@/main";

import router from '../router';

import ProgressBar from "@/components/layout/ProgressBar.vue"
import Register from "@/views/user/Register.vue"
import Access from "@/views/user/Access.vue"
import ForgotPassword from "@/views/user/ForgotPassword.vue"

import dropDownCreditorArea from '@/common/data/creditor-area.data';
import dropDownAboutProcess from '@/common/data/about-process.data';

import sidebarMenu from "@/common/data/sidebar-menu.data"

export default {
  name: "PublicLayout",

  components: {
    'progress-bar': ProgressBar,
    'register-component': Register,
    'access-component': Access,
    'forgot-password-component': ForgotPassword
  },

  data() {
    return {
      user: null,
      logoUrl: null,
      menuGroups: [],
      menuApp: false,
      drawer: false,
      dialogRegister: false,
      dialogAccess: false,
      dialogForgotPassword: false,
      searchTerm: null,
      displaySettingsMenu: false,
      sidebarMenu: sidebarMenu,
      dropDownCreditorArea: dropDownCreditorArea,
      dropDownAboutProcess: dropDownAboutProcess
    };
  },

  computed: {
    routeName() {
      return this.$route.name;
    },
  },

  created() {

    this.user = this.$root.user;

    eventHub.$on("DIALOG-REGISTER", (value) => {
      this.dialogRegister = value;
    });

    eventHub.$on("DIALOG-FORGOT-PASSWORD", (value) => {
      this.dialogForgotPassword = value;
    });

    eventHub.$on("DIALOG-ACCESS", (value) => {
      this.dialogAccess = value;
    });

    this.$ga.enable();

    eventHub.$on("logout", () => {
      this.logout();
    })

    this.setActionButtons();

  },

  destroyed() {
    eventHub.$off("THEME_UPDATED");
  },

  methods: {
    setActionButtons() {
      // Grupo
    },

    toogleMenuApp() {
      this.menuApp = !this.menuApp;
    },

    menuActionClick(event) {
      event.preventDefault()
    },

    normalizeName(name) {
      const splitName = name.split(' ');
      return splitName.length > 1 ? splitName[0] : name
    },

    async logout() {
      // if (await this.$root.$confirm(true, "user.logout")) {
      //     userApi.clearcurrentUser;
      //     this.$router.push({ name: "Login" });
      // }

      localStorage.clear();
      router.go("/home");
    },
  },


};
</script>

<style scoped lang="scss">
.title {
  color: var(--v-primary-base);
  text-decoration: none;
}

.menu-button-container {
  background-color: var(--v-secondary-base) !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80px;
  text-align: center;
}

.menu-button {
  margin: auto;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.logged-user {
  text-align: left;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 400;
  flex-direction: column;

  &-name {
    display: block;
  }

  &-email {
    display: block;
  }
}

.menu-subheader {
  padding-left: 16px;
  color: var(--v-primary-base);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.001px;
}

.menu-title {
  padding-left: 16px;
  color: var(--v-important-base);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.footer-copyright {
  background-color: var(--v-primary-base) !important;

  a {
    text-decoration: none;
  }

  span {
    color: white !important;
    font-weight: 500;
  }
}

.dropdown-menu-list {
  //background-color: var(--v-primary-base) !important;
  padding: 0px;
  color: var(--v-important-base) !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;

}

.submenu-item {
  color: rgb(255, 255, 255) !important;
  background-color: #2b3985 !important;
  padding-left: 16px;
  border-bottom: 1px solid #009986;
  font-family: 'Lucida Sans' !important;
  font-size: 12px !important;
  font-style: normal !important;
  line-height: 10px !important;
  transition: color 0.3s;
}

.submenu-text {
  font-size: 14px !important;
  font-weight: 500;
}

.menu-buttons {
  color: white !important;
  background-color: #2b4093 !important;
  border-bottom: 1px solid #009986;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-style: normal;
  transition: color 0.3s;
  border-radius: 10px;
}

.menu-buttons:hover {
  color: rgb(14, 13, 13) !important; //var(--v-secondary-base) !important;
  background-color: white !important;
}

.submenu-item:hover {
  color: var(--v-secondary-base) !important;
}

.text-white-on-hover:hover {
  color: var(--v-secondary-base) !important;
}

.text-white-on-hover {
  color: white !important;
}
</style>
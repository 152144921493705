const escapeRegExp = (regex) => {
  return regex.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

const sanitize = (string, params) => {
  let result = string;

  if (string) {
    if (typeof params === "string") {
      params = [params];
    }

    if (Array.isArray(params)) {
      for (let i = 0; i < params.length; i++) {
        var regex = new RegExp(escapeRegExp(params[i]), "g");

        result = result.replace(regex, "");
      }
    }
  }

  return result;
};

const sanitizeCpfCnpj = (value) => {
  return sanitize(value, [".", "-", "/"]);
};

const sanitizePhone = (value) => {
  return sanitize(value, ["(", ")", "-", " "]);
};

const sanitizePlate = (value) => {
  return sanitize(value, ["-", " "]);
};

export default {
  sanitize,
  sanitizeCpfCnpj,
  sanitizePhone,
  sanitizePlate,
};
